import './style.scss';
import cv from './CV_Dhifallah_Chaala.pdf';
const About = () => {
  return (
    <>
      <div className="container">
        <h1>Hello, je suis</h1>
        <div className="animation">
          <div className="first">
            <div>Chaala Dhifallah</div>
          </div>
          <div className="second">
            <div>Déveoppeuse Frontend</div>
          </div>
          <div className="third">
            <div>Déveoppeuse Backend</div>
          </div>
        </div>
      </div>
      <p className="aboutme">
        Je vous accompagne dans votre projet de site vitrine, boutique en ligne,
        blog, identité visuelle, ainsi que dans la refonte et l'optimisation de
        votre site web. Avec une approche créative et professionnelle.
      </p>
      <div className="rowabout">
        <a href={cv} target="_blank" rel="noreferrer">
          <button className="button">Download CV</button>
        </a>
        {/* <ul className="socialink">
          <li>
            <a
              href="mailto:chaalaadhif@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i aria-hidden className="fas fa-envelope"></i>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/chaaladhif"
              target="_blank"
              rel="noreferrer"
            >
              <i aria-hidden className="fab fa-github"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/chaala-dhif-588335176/"
              target="_blank"
              rel="noreferrer"
            >
              <i aria-hidden className="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li>
            <a
              href="https://discord.com/channels/@me/1103608195370450944"
              target="_blank"
              rel="noreferrer"
            >
              <i aria-hidden className="fa-brands fa-discord"></i>
            </a>
          </li>
        </ul>*/}
      </div>
    </>
  );
};
export default About;
